import React from "react";
import Video from "./assets/video/FondoLogin.mp4";

function App() {
  return (
    <>
      <div className="contenedorGeneralLogin">
        <video autoPlay="autoPlay" muted loop="loop" id="myVideo">
          <source src={Video} type="video/mp4" />
        </video>
        </div>
    </>
  );
}

export default App;
